@use 'rem-converter' as *;
@use 'sass:list';

@function get-prop-name($prop) {
  @if (list.index((m, mx, my, mt, mb, ml, mr), $prop)) {
    @return margin;
  } @else if (list.index((p, px, py, pt, pb, pl, pr), $prop)) {
    @return padding;
  } @else {
    @return null;
  }
}

@function get-prop-postfix-group($prop) {
  $postfix-group: ();

  @if (list.index((pl, ml, mx, px), $prop)) {
    $postfix-group: list.append($postfix-group, -left);
  }

  @if (list.index((pr, mr, mx, px), $prop)) {
    $postfix-group: list.append($postfix-group, -right);
  }

  @if (list.index((pt, mt, my, py), $prop)) {
    $postfix-group: list.append($postfix-group, -top);
  }

  @if (list.index((pb, mb, my, py), $prop)) {
    $postfix-group: list.append($postfix-group, -bottom);
  }

  @return $postfix-group;
}

@function get-prop-value($prop, $size) {
  @if (list.index((px, mx, pl, pr, ml, mr), $prop)) {
    @return $size;
  } @else if (list.index((py, my, pt, pb, mt, mb), $prop)) {
    @return $size;
  } @else if (list.index((p, m), $prop)) {
    @return $size;
  } @else {
    @error '#{$prop} is invalid value';
  }
}

@mixin _make-spacer($prop, $size) {
  $prop-size: rem($size);
  $prop-name: get-prop-name($prop);
  $prop-value: get-prop-value($prop, $prop-size);
  $prop-postfixes: get-prop-postfix-group($prop);

  @if (list.length($prop-postfixes) > 0) {
    @each $postfix in $prop-postfixes {
      #{$prop-name}#{$postfix}: $prop-value;
    }
  } @else {
    #{$prop-name}: $prop-value;
  }
}

@mixin make-spacer($prop, $size, $breakpoint: null) {
  @include _make-spacer($prop, $size);
}
