@use 'rem-converter' as *;

@mixin scroll {
  overflow-y: auto;
  overscroll-behavior: none;

  &::-webkit-scrollbar {
    width: rem(5px);
  }

  &::-webkit-scrollbar-corner {
    background-color: #474747;
  }

  &::-webkit-scrollbar-thumb {
    border: rem(2px) solid transparent;
    border-radius: rem(19px);
    background-clip: content-box;
    background-color: #888;
  }

  &::-webkit-scrollbar-track {
    border: rem(1px) solid #373737;
    background-color: #474747;
  }
}
