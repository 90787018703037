@use '../config' as *;

:root {
  --max-width: 1366px;
  --min-width: 320px;

  --font-size: 16px;
  --font-family: 'Roboto', sans-serif;
  --font-family-alt: 'Roboto Slab', sans-serif;
  --line-height: 1.5;
  --typography-regular: 400;
  --typography-semibold: 600;
  --color-text: #252525;
  --color-white: #fff;
  --color-black: #000;

  --color-accent: #015C8E;
  --color-secondary: #0175B7;
  --color-alt: #262626;
  --color-border: #dfdfdf;

  --gap-4: 4px;
  --gap-8: 8px;
  --gap-12: 12px;
  --gap-16: 16px;
  --gap-24: 24px;
  --gap-32: 32px;
  --gap-40: 40px;
  --gap-54: 54px;

  --nav-height: 4.375rem;

  @include media-breakpoint-up(sm) {
    --nav-height: 6.25rem;
  }
}

* {
  box-sizing: border-box;
  user-select: none;user-select

  &::after,
  &::before {
    box-sizing: border-box;
  }
}

body {
  @include scroll;

  background-color: var(--color-background);
  line-height: var(--line-height);
  color: var(--color-black);
  font-family: var(--font-family);
  font-size: var(--font-size);
  font-weight: var(--typography-regular);
  overflow-x: hidden;
  text-size-adjust: 100%;
  -webkit-overflow-scrolling: touch;
  min-width: var(--min-width);
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include make-spacer(m, 0);

  font-family: var(--font-family-alt);
  font-weight: var(--typography-semibold);
  line-height: 1.2;

  @include media-breakpoint-up(sm) {
    line-height: 2;
  }
}

$title-size: (
  '1': 56px,
  '2': 46px,
  '3': 42px,
  '4': 38px,
  '5': 26px,
  '6': 24px
);

@each $index, $status in $title-size {

  h#{$index} {
    font-size: $status - ($status * 30 / 100);

    @include media-breakpoint-up(sm) {
      font-size: $status;
    }
  }
}

a,
button {
  color: var(--color-black);

  &:focus {
    outline: none;
  }
}

button,
a {
  @include transition(all);
}

button {
  @include reset-button;
}

a {
  display: block;
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}

p {
  @include make-spacer(p, 0);
  @include make-spacer(m, 0);
}

ul,
ol {
  @include reset-list;
}

article {
  width: 100%;
}

video,
picture,
svg,
img {
  @include size(100%);

  display: block;
  object-fit: contain;
  object-position: center;
}

hr {
  @include make-spacer(my, 0);
  @include size(100%, rem(1px));

  display: block;
  border: 0;
  background-color: var(--color-black);
}

menu {
  @include make-spacer(m, 0);
  @include make-spacer(p, 0);
}

main {
  position: relative;
}

section {
  @include make-spacer(py, rem(50px));

  @include media-breakpoint-up(md) {
    @include make-spacer(py, rem(100px));

    min-height: 100dvh;
  }
}