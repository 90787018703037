@import "scss/config";

.block {
  @include make-spacer(px, var(--gap-24));
  @include flex($align-items: center);

  position: relative;
  overflow: hidden;
  min-height: rem(650px);
  max-height: rem(800px);
  height: 100dvh;

  @include media-breakpoint-up(sm) {
    @include make-spacer(px, var(--gap-40));

    max-height: unset;
  }
}

.title,
.subtitle {
  color: currentColor;
  line-height: 1.2;

  @include media-breakpoint-up(md) {
    line-height: 1.5
  }
}

.image,
.picture {
  object-fit: cover;
}

.slider {
  position: absolute;
  inset: 0;
}

.picture {
  position: relative;

  &::before {
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: $z-index-2;
    content: ''
  }
}

.content {
  @include flex($align-items: flex-start, $direction: column);

  position: relative;
  z-index: $z-index-1;
  color: var(--color-white);
  gap: var(--gap-16);

  @include media-breakpoint-up(md) {
    max-width: rem(900px);
  }
}

.actions {
  display: grid;
  gap: var(--gap-16);
  grid-template-columns: repeat(2, 1fr);
}

.subtitle {
  font-size: rem(20px);

  @include media-breakpoint-up(sm) {
    font-size: rem(28px);
  }
}

:global {

  .swiper {
    position: relative;
    height: 100%;

    .swiper-pagination {      
      bottom: rem(50px);
      z-index: $z-index-1;
    }

    .swiper-pagination-bullet {
      @include size(rem(12px));

      flex-shrink: 0;
      opacity: 1;
      background-color: var(--color-border);

      &-active {
        background-color: var(--color-accent);
        border: rem(1px) solid var(--color-white);
      }
    }
  }
}