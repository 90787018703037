@import "scss/config";

.block {
  @include make-spacer(p, rem(24px));
  @include transition;

  display: grid;
  grid-template-rows: auto auto 1fr auto;
  gap: var(--gap-16);
  position: fixed;
  top: var(--nav-height);
  right: 0;
  bottom: 0;
  width: rem(320px);
  background-color: var(--color-white);
  transform: translateX(100%);
  border-left: rem(1px) solid var(--color-border);

  &.active {
    transform: translateX(0);
  }
}