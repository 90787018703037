@import "scss/config";

.block {
  @include flex(center, center);

  gap: var(--gap-4)
}

.link {
  color: var(--color-accent);

  @include hover {
    
    &:hover {
      text-decoration: underline;
    }
  }
}
