@import "scss/config";

.block {
  @include make-spacer(py, var(--gap-40));

  background-color: var(--color-white);
}

.left,
.right,
.center {
  @include flex($align-items: center, $direction: column);

  gap: var(--gap-16);
}

.left,
.right {

  @include media-breakpoint-up(lg) {
    @include make-spacer(pt, var(--gap-24));
  }
}

.wrapper {
  display: grid;
  gap: var(--gap-16);

  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.left {

  @include media-breakpoint-up(lg) {
    align-items: flex-end;
  }
}

.right {
  text-align: center;

  @include media-breakpoint-up(lg) {
    align-items: flex-start;
    text-align: left;
  }
}