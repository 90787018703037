@import "scss/config";

.link {
  color: var(--color-accent);

  @include hover {
    
    &:hover {
      text-decoration: underline;
    }
  }
}
