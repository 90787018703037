@import "scss/config";

.block {
  @include make-spacer(py, var(--gap-12));
  @include make-spacer(px, var(--gap-16));

  display: inline-block;
  background-color: var(--color-alt);
  position: relative;
  min-width: rem(150px);
  text-align: center;

  &::before {
    @include transition;

    position: absolute;
    inset: 0;
    width: 0;
    background-color: var(--color-accent);
    content: '';
  }

  @include hover {

    &:hover {

      &::before {
        width: 100%;
      }
    }
  }
}

.text {
  position: relative;
  z-index: $z-index-1;
  color: var(--color-white);
}
