@import "scss/config";

.block {
  @include flex($direction: column);

  overflow: hidden;
  gap: var(--gap-8);
}

.link {
  @include transition;

  font-size: rem(20px);

  @include media-breakpoint-up(sm) {
    font-size: rem(24px);
  }

  &.active {
    color: var(--color-accent);
  }

  @include hover {
    
    &:hover {
      transform: translateX(rem(20px));
    }
  }
}