@import "scss/config";

.block {
  @include flex($direction: column);

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--color-white);
  border-bottom: rem(1px) solid var(--color-border);
  height: var(--nav-height);
  z-index: $z-index-8;
}

.wrapper {
  @include flex(space-between, center);
}

.cell {

  &:nth-child(2) {
    flex-grow: 1;
  }

  &:nth-child(3) {
    @include make-spacer(mr, var(--gap-8));
  }
}
