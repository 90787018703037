@import "scss/config";

.block {
  display: grid;
  grid-template-columns: repeat(4, rem(40px));
  gap: rem(var(--gap-8));
}

.link {
  @include flex(center, center);
  @include size(rem(40px));
  @include make-spacer(p, var(--gap-8));

  @include hover {
    
    &:hover {
      background-color: var(--color-accent);
      color: var(--color-white);
    }
  }
}