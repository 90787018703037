@import 'scss/config';

.block {
  @include flex(center, center);
  
  position: fixed;
  inset: 0;
  z-index: $z-index-11;
  background-color: var(--color-white);
}

.spin {
  --b: 8px; 

  @include make-spacer(p, rem(1px));

  width: rem(50px);
  aspect-ratio: 1;
  border-radius: 50%;
  background: conic-gradient(transparent 10%, var(--color-accent, var(--color-black))) content-box;
  -webkit-mask:
    repeating-conic-gradient(transparent 0deg, var(--color-black) 1deg 20deg, transparent 21deg 36deg),
    radial-gradient(farthest-side, transparent calc(100% - var(--b) - 1px), var(--color-black) calc(100% - var(--b)));
  -webkit-mask-composite: destination-in;
          mask-composite: intersect;
  animation: spin 1s infinite steps(10);
}

@keyframes spin {
  to {
    transform: rotate(1turn)
  }
}