@import "scss/config";

.block {
  @include size(var(--nav-height));
  @include flex(center, center, column);

  @include media-breakpoint-up(sm) {
    border-left: rem(1px) solid var(--color-border);
  }

  &.active {

    .line {

      &:nth-child(1) {
        transform: translateY(rem(10px)) rotateZ(45deg);
      }

      &:nth-child(2) {
        transform: translateX(100%);
        opacity: 0;
      }

      &:nth-child(3) {
        transform: translateY(rem(-10px)) rotateZ(-45deg);
      }
    }
  }
}

.line {
  @include transition;
  @include make-spacer(my, rem(4px));
  @include size(70%, rem(2px));

  flex-shrink: 0;
  background-color: var(--color-black);
}
