@import 'scss/config';

.block {
  @include flex($align-items: center);

  position: relative;
  z-index: 1;

  &.active {

    .dropdown {
      display: block;
    }

    .arrow {
      transform: rotateZ(0);
      color: var(--color-accent);
    }
  }
}

.checked {
  @include flex(center, center);

  cursor: pointer;
  text-transform: uppercase;
}

.text {
  @include make-spacer(mr, var(--gap-4));
}

.arrow {
  @include transition;

  transform: rotateZ(180deg);
  color: var(--color-border);
}

.dropdown {
  display: none;
  position: absolute;
  top: rem(30px);
  right: rem(-15px);
  background-color: var(--color-white);
  border: rem(1px) solid var(--color-border);
  width: rem(120px);
}

.button {
  @include size(100%, rem(40px));
  @include flex($align-items: center);
  @include make-spacer(px, var(--gap-12));

  border-bottom: rem(1px) solid var(--color-border);

  &:last-child {
    border-bottom: 0;
  }

  @include hover {
    
    &:hover {
      color: var(--color-accent);
    }
  }
  
  &.active {
    pointer-events: none;
    color: var(--color-accent);
    background-color: var(--color-border);
  }
}

.icon {
  @include make-spacer(mr, var(--gap-8));

  width: rem(25px);
}
